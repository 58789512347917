import React, { Component } from "react";

export default class Result extends Component {
    constructor(props) {
        super(props);


    }

    renderCh() {
        var item = this.props.data;


        return (


            <div className="col-lg-4" >
                <div style={{ padding: 15, border: "2px solid white", marginTop: 15, borderRadius: 12 }}>
                    <div style={{ textTransform: "uppercase", textAlign: "center", width: "100%", fontWeight: "bold", fontSize: 17 }}>{item.name}</div>
                    <div style={{ textAlign: "center", width: "100%", marginBottom: 15, marginTop: 15 }}>
                        <span style={{ fontWeight: "bold", fontSize: 31 }}>
                            <div>{item.q.d1} - {item.q.t1}(t) </div>
                            <div>{item.q.d2} - {item.q.t2}(t) </div>
                            <div>{item.q.d3} - {item.q.t3}(t) </div>
                            <div>{item.q.d4} - {item.q.t4}(t)</div>
                        </span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {item.description}
                    </div>

                </div>

            </div>

        );
    }

    renderNa() {
        var item = this.props.data;


        return (


            <div className="col-lg-4" >
                <div style={{ padding: 15, border: "2px solid white", marginTop: 15, borderRadius: 12 }}>
                    <div style={{ textTransform: "uppercase", textAlign: "center", width: "100%", fontWeight: "bold", fontSize: 17 }}>{item.name}</div>
                    <div style={{ textAlign: "center", width: "100%", marginBottom: 15, marginTop: 15 }}>
                        <span style={{ fontWeight: "bold", fontSize: 31 }}>
                            <div>{item.q.n1} - {item.q.t1} </div>
                            <div>{item.q.n2} - {item.q.t2} </div>
                            <div>{item.q.n3} - {item.q.t3} </div>
                            <div>{item.q.n4} - {item.q.t4} </div>
                            <div>{item.q.n5} - {item.q.t5} </div>
                        </span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {item.description}
                    </div>

                </div>

            </div>

        );
    }

    renderThth() {
        var item = this.props.data;


        return (


            <div className="col-lg-4" >
                <div style={{ padding: 15, border: "2px solid white", marginTop: 15, borderRadius: 12 }}>
                    <div style={{ textTransform: "uppercase", textAlign: "center", width: "100%", fontWeight: "bold", fontSize: 17 }}>{item.name}</div>
                    <div style={{ textAlign: "center", width: "100%", marginBottom: 15, marginTop: 15 }}>
                        <span style={{ fontWeight: "bold", fontSize: 31 }}>
                            <div>{item.q.t1} </div>
                            <div>{item.q.t2} </div>
                            <div>{item.q.t3} </div>
                            <div> {item.q.t4} </div>

                        </span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {item.description}
                    </div>

                </div>

            </div>

        );
    }

    renderThang() {
        var item = this.props.data;


        return (


            <div className="col-lg-4" >
                <div style={{ padding: 15, border: "2px solid white", marginTop: 15, borderRadius: 12 }}>
                    <div style={{ textTransform: "uppercase", textAlign: "center", width: "100%", fontWeight: "bold", fontSize: 17 }}>{item.name}</div>
                    <div style={{ textAlign: "center", width: "100%", marginBottom: 15, marginTop: 15 }}>
                        <span style={{ fontWeight: "bold", fontSize: 31 }}>
                            <div>{item.q.d1} - {item.q.t1} </div>
                            <div> {item.q.d2} - {item.q.t2} </div>
                            <div>{item.q.d3} - {item.q.t3} </div>


                        </span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {item.description}
                    </div>

                </div>

            </div>

        );
    }




    render() {
        var item = this.props.data;
        if (item.id == 'ch') {
            return this.renderCh();
        }
        if (item.id == 'na') {
            return this.renderNa();
        }

        if (item.id == 'thth') {
            return this.renderThth();
        }

        if (item.id == 'thang') {
            return this.renderThang();
        }


        return (


            <div className="col-lg-4" >
                <div style={{ padding: 15, border: "2px solid white", marginTop: 15, borderRadius: 12 }}>
                    <div style={{ textTransform: "uppercase", textAlign: "center", width: "100%", fontWeight: "bold", fontSize: 17 }}>{item.name}</div>
                    <div style={{ textAlign: "center", width: "100%", marginBottom: 15, marginTop: 15 }}>
                        <span style={{ fontWeight: "bold", fontSize: 31 }}>
                            {item.q}
                        </span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {item.description}
                    </div>

                </div>

            </div>

        );
    }
}





