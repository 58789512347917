import React, { Component } from "react";

import { UserOutlined, LaptopOutlined, NotificationOutlined } from "@ant-design/icons";
import { Table, Tag, Space, Button, Input, Modal, DatePicker, message, Row, Col } from "antd";

import { Card } from "antd";
import moment from "moment";
import { Api } from "services";

const { Search } = Input;

export default class AccountManagement extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], search: { phone: "" } };
    this.Popup = React.createRef();
  }
  componentDidMount() {
    this.getListAcc();
  }

  getListAcc = () => {
    Api.getUrl("admin/GetListAcc", this.state.search).then((data) => {
      this.setState({ data: data });
    });
  };

  open = (id) => {
    this.Popup.current.open(id);
  };

  onSearch = (e) => {
    var search = { ...this.state.search };
    search.phone = e;
    this.setState({ search: search }, this.getListAcc);
  };

  render() {
    return (
      <>
        <div>
          <Search placeholder="Nội dung tìm kiếm" onSearch={this.onSearch} />
        </div>
        <div style={{ paddingTop: 15 }}>
          <div className="float-right">
            <Button
              type="primary"
              onClick={() => {
                this.open(0);
              }}
            >
              Thêm mới
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="row">
          {this.state.data.map((item) => {
            var ReportLimit = item.ReportLimit != null ? item.ReportLimit : 0;
            var ReportCreated = item.ReportCreated != null ? item.ReportCreated : 0;
            return (
              <div className="col-lg-4" key={"accccc_" + item.ID}>
                <div style={{ backgroundColor: "#0a142e", borderRadius: 12, padding: 15, marginTop: 15 }}>
                  <div>
                    <div className="float-left">
                      <div>{item.Name}</div>
                      <div>{item.Phone}</div>
                      {item.Role != "admin" && <div>RP còn lại: {ReportLimit - ReportCreated}</div>}
                    </div>
                    <div className="float-right" style={{ textAlign: "right" }}>
                      <div>{moment(item.CreateAt).format("DD-MM-YY")}</div>
                      <div>
                        <Button type="link">Xem</Button>
                        <Button
                          type="link"
                          onClick={() => {
                            this.open(item.ID);
                          }}
                        >
                          Sửa
                        </Button>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Popup ref={this.Popup} onSaveChange={this.getListAcc}></Popup>
      </>
    );
  }
}

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, data: {} };
  }

  open = (id) => {
    if (id == 0) {
      var data = {
        ID: 0,
        Phone: "",
        Email: "",
        Name: "",
        ValidTo: moment().add(30).format("MM-DD-YYYY"),
        Note: "",
        Pass: "123321",
        ReportLimit: 0,
      };
      this.setState({ visible: true, data: data });
    } else {
      Api.getUrl("admin/GetAccByID", { id: id }).then((data) => {
        this.setState({ visible: true, data: data });
      });
    }
  };

  onDataChange = (name, value) => {
    var data = { ...this.state.data };
    data[name] = value;
    this.setState({ data: data });
  };

  close = () => {
    this.setState({ visible: false });
  };
  save = () => {
    var post = this.state.data;
    Api.post("admin/SaveAccount", post).then((data) => {
      if (data.Code == 1) {
        message.success("Đã lưu xong");
        this.props.onSaveChange();
        this.close();
      } else {
        message.error(data.Message);
      }
    });
  };

  render() {
    var item = this.state.data;
    return (
      <Modal title="Tài khoản" visible={this.state.visible} onOk={this.save} onCancel={this.close}>
        <div>
          <div className="form-group">
            <label>Tên</label>
            <input
              className="form-control"
              value={item.Name}
              onChange={(e) => {
                this.onDataChange("Name", e.target.value);
              }}
            ></input>
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              className="form-control"
              value={item.Phone}
              onChange={(e) => {
                this.onDataChange("Phone", e.target.value);
              }}
            ></input>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              className="form-control"
              value={item.Email}
              onChange={(e) => {
                this.onDataChange("Email", e.target.value);
              }}
            ></input>
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              className="form-control"
              value={item.Pass}
              onChange={(e) => {
                this.onDataChange("Pass", e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <Row>
              <Col span={12}>
                <label>Ngày hết hạn</label>
                <div>
                  <DatePicker
                    onChange={(e) => {
                      this.onDataChange("ValidTo", e.format("MM-DD-YYYY"));
                    }}
                    value={moment(item.ValidTo)}
                  />
                </div>
              </Col>
              <Col span={12}>
                <label>Số báo cáo</label>
                <input
                  className="form-control"
                  type="number"
                  value={item.ReportLimit}
                  onChange={(e) => {
                    this.onDataChange("ReportLimit", e.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <label>Ghi chú</label>
            <textarea
              className="form-control"
              rows="4"
              onChange={(e) => {
                this.onDataChange("Note", e.target.value);
              }}
              value={item.Note ?? ""}
            ></textarea>
          </div>
        </div>
      </Modal>
    );
  }
}
