import React, { Component } from "react";
import Home from "components/home";
import Result from "components/result";
import Management from "components/acount_management";
import { Api } from "services";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AccountManagement from "components/acount_management";

import { Modal, Button, Space } from "antd";

export default class Logged extends Component {
  constructor(props) {
    super(props);
    this.state = { logged: false, user: {} };
  }

  componentDidMount() {
    try {
      let user = JSON.parse(localStorage.getItem("User"));

      if (!user) {
        user = {};
      }

      Api.getUrl("Login/GetTokenInfo", { token: user.Token }).then((data) => {
        if (data.Code == 1) {
          this.setState({ logged: true, user: data.User });
        } else {
          if (data.Message) {
            Modal.error({
              title: "Lỗi",
              content: data.Message,
              onOk: this.gotoLogin,
            });
          } else {
            this.gotoLogin();
          }
        }
      });
    } catch (ex) {
      this.gotoLogin();
    }
  }
  gotoLogin = () => {
    window.location = "/login";
  };

  logout = () => {
    localStorage.removeItem("User");
    this.gotoLogin();
  };

  renderLogged() {
    return (
      <>
        <nav className="navbar navbar-expand-md " style={{ padding: 0 }}>
          <div className="container navbar-dark bg-dark">
            <a href="#" className="navbar-brand mr-3">
              Thần số học
            </a>
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ml-auto">
                {this.state.user.Role == "admin" && (
                  <>
                    <a href="/accounts" className="nav-item nav-link">
                      Tài khoản
                    </a>
                    <a href="/home" className="nav-item nav-link">
                      Home
                    </a>
                  </>
                )}

                <a href="#" className="nav-item nav-link" onClick={this.logout}>
                  Đăng xuất
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div
          className="container"
          style={{
            backgroundImage: "url('/images/main_bg.jpg')",
            color: "white",
            minHeight: 1300,
            backgroundSize: "100% auto",
          }}
        >
          <div style={{ paddingTop: 15 }}>
            <Router>
              <Switch>
                <Route path="/home">
                  <Home role = {this.state.user.Role} />
                </Route>
                <Route path="/accounts">
                  <AccountManagement />
                </Route>
              </Switch>
            </Router>
          </div>
          <hr />
          <footer>
            <div className="row">
              <div className="col-md-6">
                <p>Copyright © 2019 Tutorial Republic</p>
              </div>
              <div className="col-md-6 text-md-right">
                <a href="#" className="text-dark">
                  Terms of Use
                </a>
                <span className="text-muted mx-2">|</span>
                <a href="#" className="text-dark">
                  Privacy Policy
                </a>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }

  render() {
    if (this.state.logged) {
      return this.renderLogged();
    } else {
      return <div>loading</div>;
    }
  }
}
