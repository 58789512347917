"use strict";
import _api from "./api";

var api_url = "https://thansohocuytin.vn:8088/api";

var Api = new _api({
  url: api_url,
  port: "",
});

export { Api };
