import logo from "./logo.svg";
import "./App.css";

import Login from "components/login";
import Result from "components/result";
import Logged from "components/logged";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/result">
          <Result />
        </Route>
        <Route path="/">
          <Logged />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
