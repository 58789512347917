import React, { Component } from "react";
import { message } from "antd";
import { Api } from "services";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { login: { User: "", Pass: "" } };
  }

  onDataChange = (name, value) => {
    var data = { ...this.state.login };
    data[name] = value;
    this.setState({ login: data });
  };

  login = () => {
    var post = this.state.login;
    Api.post("login/index", post).then((data) => {
      if (data.Code == 1) {
        localStorage.setItem("User", JSON.stringify(data.User));
        window.location = "/home";
      } else {
        message.error(data.Message);
      }
    });
  };

  render() {
    var item = this.state.login;
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <h5 className="card-title text-center">Đăng nhập</h5>
                <form className="form-signin">
                  <div className="form-label-group">
                    <label htmlFor="inputEmail">Phone number</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Phone number"
                      required
                      autoFocus
                      value={item.User}
                      onChange={(e) => {
                        this.onDataChange("User", e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-label-group" style={{ marginTop: 10 }}>
                    <label htmlFor="inputPassword">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      value={item.Pass}
                      onChange={(e) => {
                        this.onDataChange("Pass", e.target.value);
                      }}
                    />
                  </div>
                  <span
                    className="btn btn-lg btn-primary btn-block text-uppercase"
                    style={{ marginTop: 10 }}
                    type="submit"
                    onClick={this.login}
                  >
                    Đăng nhập
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
