import React, { Component } from "react";

import { UserOutlined, LaptopOutlined, NotificationOutlined } from "@ant-design/icons";
import { Table, Tag, Space, Button, Input, Modal, DatePicker, message, Row, Col } from "antd";
import copy from "copy-to-clipboard";
import { Card } from "antd";
import moment from "moment";
import { Api } from "services";
import { Select } from "antd";

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: { beginCreateAt: moment().add(-30, "days"), endCreateAt: moment() },
      lstUser: [],
      totalRP: 0,
    };
    this.Popup = React.createRef();
  }
  componentDidMount() {
    this.getListTestQ();
    if (this.props.role == "admin") {
      this.getAllAcc();
    }
  }

  getListTestQ = () => {
    var post = this.state.search;
    Api.post("user/getListTestQ", post).then((data) => {
      this.setState({ data: data.lst, totalRP: data.total });
    });
  };

  open = (id) => {
    this.Popup.current.open(id);
  };
  copyLink = (url) => {
    var urlR = `https://thansohocuytin.vn:8088${url}`;
    copy(urlR);
    message.success("Đã copy");
  };

  openLink = (url) => {
    var urlR = `https://thansohocuytin.vn:8088${url}`;
    window.open(urlR, "_blank");
  };
  onSearch = (e) => {
    var search = { ...this.state.search };
    search.Info = e;
    this.setState({ search: search }, this.getListTestQ);
  };

  getAllAcc = () => {
    Api.get("Admin/GetAllAcc").then((data) => {
      this.setState({ lstUser: data });
    });
  };
  handleChange = (value) => {
    console.log(`selected ${value}`);
    var search = { ...this.state.search };
    search.UserID = value;
    this.setState({ search: search }, this.getListTestQ);
  };
  changeCreateAt = (dates, dateStrings) => {
    var search = { ...this.state.search };

    search.beginCreateAt = dates[0];
    search.endCreateAt = dates[1];

    this.setState({ search: search }, this.getListTestQ);
  };
  render() {
    return (
      <>
        <div>
          <Search placeholder="Nội dung tìm kiếm" onSearch={this.onSearch} />

          {this.props.role == "admin" && (
            <div style={{ marginTop: 15 }}>
              <Row gutter={16}>
                <Col span={18}>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Chọn người tạo"
                    onChange={this.handleChange}
                  >
                    {this.state.lstUser.map((item) => {
                      return (
                        <Option key={"acc" + item.ID} value={item.ID}>
                          {item.Name}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={6}>
                  <RangePicker
                    value={[this.state.search.beginCreateAt, this.state.search.endCreateAt]}
                    onChange={this.changeCreateAt}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div style={{ paddingTop: 15 }}>
          <Row>
            <Col span={12}>
              <span>Tổng: {this.state.totalRP}</span>
            </Col>
            <Col span={12}>
              {" "}
              <div className="float-right">
                <Button
                  type="primary"
                  onClick={() => {
                    this.open(0);
                  }}
                >
                  Thêm mới
                </Button>
              </div>
            </Col>
          </Row>
          <div className="clearfix"></div>
        </div>
        <div className="row">
          {this.state.data.map((item) => {
            return (
              <div className="col-lg-4" key={"accccc_" + item.ID}>
                <div style={{ backgroundColor: "#0a142e", borderRadius: 12, padding: 15, marginTop: 15 }}>
                  <div>
                    <div className="float-left">
                      <div>{item.Name}</div>
                      <div>{item.Phone}</div>
                    </div>
                    <div className="float-right" style={{ textAlign: "right" }}>
                      <div>{moment(item.CreateAt).format("DD-MM-YY")}</div>
                      <div>
                        <Button type="link" onClick={() => this.copyLink(item.PDFResultUrl)}>
                          Copy Link
                        </Button>
                        <Button type="link" onClick={() => this.openLink(item.PDFResultUrl)}>
                          Xem
                        </Button>
                        <Button
                          type="link"
                          onClick={() => {
                            this.open(item.ID);
                          }}
                        >
                          Sửa
                        </Button>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Popup ref={this.Popup} onSaveChange={this.getListTestQ}></Popup>
      </>
    );
  }
}

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, data: {} };
  }

  open = (id) => {
    if (id == 0) {
      var data = {
        ID: "",
        Phone: "",
        Name: "",
        BirthDate: moment().add(30).format("MM-DD-YYYY"),
      };
      this.setState({ visible: true, data: data });
    } else {
      Api.getUrl("user/GetTestQByID", { id: id }).then((data) => {
        this.setState({ visible: true, data: data });
      });
    }
  };

  onDataChange = (name, value) => {
    var data = { ...this.state.data };
    data[name] = value;
    this.setState({ data: data });
  };

  close = () => {
    this.setState({ visible: false });
  };
  save = () => {
    var post = this.state.data;
    Api.post("user/SaveTestQ", post).then((data) => {
      if (data.Code == 1) {
        message.success("Đã lưu xong");
        this.props.onSaveChange();
        this.close();
      } else {
        message.error(data.Message);
      }
    });
  };

  render() {
    var item = this.state.data;
    return (
      <Modal title="Test" visible={this.state.visible} onOk={this.save} onCancel={this.close}>
        <div>
          <div className="form-group">
            <label>Tên</label>
            <input
              className="form-control"
              value={item.Name}
              onChange={(e) => {
                this.onDataChange("Name", e.target.value);
              }}
            ></input>
          </div>
          <div className="form-group">
            <label>Số điện thoại</label>
            <input
              className="form-control"
              value={item.Phone}
              onChange={(e) => {
                this.onDataChange("Phone", e.target.value);
              }}
            ></input>
          </div>

          <div className="form-group">
            <label>Ngày sinh</label>

            <div>
              <DatePicker
                onChange={(e) => {
                  this.onDataChange("BirthDate", e.format("MM-DD-YYYY"));
                }}
                value={moment(item.BirthDate)}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
